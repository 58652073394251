<template>
  <v-card
    height="100%"
  >
    <v-card-title class="pl-6 pt-4 font-weight-light">
      Top Ten SMS Callers by Spend ($)
    </v-card-title>
    <div style="max-width:480px">
      <apexchart
        type="donut"
        :options="chartOptions"
        :series="pieSeries"
      />
    </div>
  </v-card>
</template>

<script>
  import moment from 'moment'
  import { findMonthlyTopTen } from '@/api/PouchDaoReport'
  import { mapGetters } from 'vuex'
  import store from '@/store'
  import { appConfig } from '@/appConfig'

  //
  // import { createNamespacedHelpers } from 'vuex'
  // const { mapGetters } = createNamespacedHelpers('usage')

  export default {
    name: 'UsageSMSTopTenPieChart',
    props: {
      dataYearMonth: {
        type: Number,
      },
    },
    computed: {
      ...mapGetters('app', {
        customerInfo: 'customerInfo',
        snCount: 'snCount', //, ...
      }),
      ...mapGetters('usage', {
        refreshRequired: 'refreshRequired',
      }),
      yearMonthSelectList: function () {
        const yearMonthList = []
        this.customerInfo.yearMonthSelectList.forEach(yearMonthItem => {
          yearMonthList.push(yearMonthItem.label)
        })
        return yearMonthList
      },
    },
    watch: {
      dataYearMonth: {
        handler () {
          console.log('watching dataYearMonth changed to ' + this.dataYearMonth)
          this.refreshFromSelectedYearMonth(this.dataYearMonth)
        },
      },
    },

    data () {
      return {
        selectedMonth: {},
        refreshData: true,
        chartOptions: {
          chart: {
            events: {
              dataPointSelection: (e, chart, opts) => {
                // you can call Vue methods now as "this" will point to the Vue instance when you use ES6 arrow function
                this.topTenPointSelected(opts)
              },
            },
          },
          plotOptions: {
            pie: {
              customScale: 0.9,
              expandOnClick: false,
              donut: {
                size: '60%',
                labels: {
                  show: true,
                },
              },
            },
          },
          noData: {
            text: 'No data',
            align: 'center',
            verticalAlign: 'middle',
            offsetX: 0,
            offsetY: 0,
            style: {
              color: '#BEC932',
              fontSize: '12px',
              fontFamily: undefined,
            },
          },
          dataLabels: {
            enabled: true,
          },
          tooltip: {
            enabled: false,
          },
          legend: {
            show: true,
            horizontalAlign: 'left',
            offsetX: -30,
            offsetY: 0,
          },
          states: {
            normal: {
              filter: {
                type: 'none',
                value: 0,
              }
            },
            hover: {
              filter: {
                type: 'lighten',
                value: 0,
              }
            },
            active: {
              allowMultipleDataPointsSelection: false,
              filter: {
                type: 'none',
                value: 0,
              }
            },
          },
          colors: appConfig.colors,
          fill: {
            type: 'gradient',
          },
        },
      }
    },
    mounted () {
      if (this.refreshData) {
        this.selectedMonth = this.customerInfo.latest
        this.switchTopTen('SMS')
        this.refreshData = false
      }
    },
    methods: {
      topTenPointSelected (opts) {
        console.log(' label ' + this.chartOptions.labels[opts.dataPointIndex] + ' value ' + this.pieSeries[opts.dataPointIndex])
        store.dispatch('usage/updateSnFilter', { sn: this.chartOptions.labels[opts.dataPointIndex] })
        // store.dispatch('usage/updateEquipmentTableFilter', {category: this.dataCategory, label: this.chartOptions.labels[opts.dataPointIndex]})
      },

      switchTopTen (smsDataCategory) {
        console.log('switchTopTen ' + smsDataCategory + ' calling refreshData')
        this.refreshTopTen(smsDataCategory, this.selectedMonth)
      },

      refreshFromSelectedYearMonth (yearMonth) {
        console.log('refreshFromSelectedYearMonth ' + yearMonth)
        this.refreshTopTen('SMS', yearMonth)
      },
      refreshTopTen (voiceCategory, yearMonth) {
        console.log('switchTopTen ' + voiceCategory + ' calling findMonthlyTopTen')
        findMonthlyTopTen('DOMESTIC', voiceCategory, yearMonth).then(result => {
          console.log('findMonthlyTopTen ' + voiceCategory + ' completed')
          const chartData = []
          const labels = []
          result.forEach(pieData => {
            labels.push(pieData.sn + '')
            chartData.push(pieData.qty)
          })
          this.pieSeries = chartData // copy the array...
          this.chartOptions = {
            labels: labels,
          }
        })
      },

    },

  }
</script>

<style scoped>

</style>
