<template>
  <v-card
    height="100%"
    align="center"
  >
    <v-card-title class="pl-6 pt-4 font-weight-light">
      12mth Overseas Data Usage (Mb)
    </v-card-title>
    <apexchart
      type="area"
      height="330"
      :options="chartOptions"
      :series="series"
    />
  </v-card>
</template>

<script>
  import moment from 'moment'
  import { mapGetters } from 'vuex'
  import { find12MonthsOverseasDataTotalCall } from '@/api/PouchDaoReport'
  import Utils from '@/api/util'

  export default {
    name: 'UsageOverseasData12MonthsLineChart',
    props: {
      dataYearMonth: {
        type: Number,
      },
    },
    data () {
      return {
        refreshData: true,
        cacheChartData: [],
        series: [{
          // as below
        }],
        chartOptions: {
          colors: ['#42B3F4'],
          chart: {
            stacked: false,
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'smooth',
            width: [2, 0.3],
          },
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              type: 'vertical',
              shadeIntensity: 0.1,
              gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
              inverseColors: true,
              opacityFrom: [0.7],
              opacityTo: [0.4],
              stops: [0],
              colorStops: [],
            },
          },
          markers: {
            size: 0,
            style: 'hollow',
          },
          xaxis: {
            labels: {
              formatter: function (value) {
                const dateValue = moment(value + '01 +0000', 'YYYYMMDD Z')
                return dateValue.format('MMM YY')
              },
              offsetX: -5,
              style: {
                colors: '#4D4D4D',
                fontSize: '11px',
              },
            },
          },
          yaxis: [
            {
              seriesName: 'Data Usage',
              labels: {
                style: {
                  color: '#4D4D4D',
                  fontSize: '11px',
                },
                formatter: function (value) {
                  return Utils.readableBytes(value)
                },
              },
              title: {
                text: 'Data Usage (Mb)',
                offsetX: 0,
                style: {
                  color: '#959494',
                  fontWeight: 400,
                  fontFamily: 'Roboto, sans-serif',
                  fontSize: '11px',
                },
              },
            },
          ],
          tooltip: {
            x: {
              format: 'MMM yyyy',
            },
            grid: {
              row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5,
              },
            },
          },
        },
      }
    },
    computed: {
      ...mapGetters('app', {
        customerInfo: 'customerInfo',
        snCount: 'snCount', //, ...
      }),
      ...mapGetters('usage', {
        overseasDataCategory: 'overseasDataCategory',
        refreshRequired: 'refreshRequired',
        selectedTab: 'selectedTab',
      }),
    },
    watch: {
      overseasDataCategory: {
        handler () {
          this.switch12MonthsTotal(this.overseasDataCategory)
        },
      },
      dataYearMonth: {
        handler () {
          console.log('watching dataYearMonth changed to ' + this.dataYearMonth)
          this.updateAnnotationOnChart(this.dataYearMonth)
        },
      },
      selectedTab: {
        handler () {
          console.log('selected tab', this.selectedTab)
          if (this.selectedTab === 'INTERNATIONAL') {
            this.switch12MonthsTotal(this.domesticVoiceCategory)
          }
        },
      },

    },
    mounted () {
      if (this.refreshData) {
        find12MonthsOverseasDataTotalCall(this.customerInfo.earliest, this.customerInfo.latest).then(result => {
          console.log('find12MonthsOverseasDataTotalCall data total call completed')
          this.cacheChartData = result // result contains {totalCall , totalDuration, totalUsage}
          this.minYearMonth = this.customerInfo.earliest
          this.maxYearMonth = this.customerInfo.latest
          this.switch12MonthsTotal(this.overseasDataCategory)
          this.refreshData = false
        })
      }
    },
    methods: {
      updateAnnotationOnChart (yearMonth) {
        console.log('updateAnnotationOnChart yearMonth is ' + yearMonth)
        const dateValue = moment(yearMonth + '01 +0000', 'YYYYMMDD Z')
        this.chartOptions = {
          annotations: {
            xaxis: [
              {
                // in a datetime series, the x value should be a timestamp, just like it is generated below
                // x: moment(yearMonth + '01 +0000', 'YYYYMMDD Z').valueOf(),
                x: dateValue.format('MMM YY'),
                strokeDashArray: 0,
                borderColor: '#775DD0',
                label: {
                  borderColor: '#775DD0',
                  style: {
                    color: '#fff',
                    background: '#775DD0',
                  },
                  text: 'Top ten',
                },
              },
            ],
          },
        }
      },
      switch12MonthsTotal (dataCategory) {
        let chartType = 'area'
        if (dataCategory === 'CALL') {
          let chartData = []
          this.cacheChartData.totalCall.forEach(dataCall => {
            // console.log('year month ' + dataCall.yearMonth + ' call total ' + dataCall.call)
            // chartData.push([moment(dataCall.yearMonth + '01 +0000', 'YYYYMMDD Z').valueOf(), Math.round(dataCall.total)])
            chartData.push({ x: dataCall.yearMonth, y: Math.round(dataCall.total)})
          })
          this.updateLineChartData(chartType, { dataCategory: dataCategory, min: this.customerInfo.earliest, max: this.customerInfo.latest, data: chartData })
        }
        if (dataCategory === 'DURATION') {
          let chartData = []
          this.cacheChartData.totalDuration.forEach(dataCall => {
            // chartData.push([moment(dataCall.yearMonth + '01 +0000', 'YYYYMMDD Z').valueOf(), Math.round(dataCall.total)])
            chartData.push({ x: dataCall.yearMonth, y: Math.round(dataCall.total)})
          })
          this.updateLineChartData(chartType, { dataCategory: dataCategory, min: this.customerInfo.earliest, max: this.customerInfo.latest, data: chartData })
        }
        if (dataCategory === 'USAGE') {
          let chartData = []
          this.cacheChartData.totalUsage.forEach(dataCall => {
            // chartData.push([moment(dataCall.yearMonth + '01 +0000', 'YYYYMMDD Z').valueOf(), Math.round(dataCall.total)])
            chartData.push({ x: dataCall.yearMonth, y: Math.round(dataCall.total)})
          })
          this.updateLineChartData(chartType, { dataCategory: dataCategory, min: this.customerInfo.earliest, max: this.customerInfo.latest, data: chartData })
        }
        if (dataCategory === 'EXCESS') {
          let chartData = []
          chartType = 'bar'
          this.cacheChartData.totalExcess.forEach(dataCall => {
            // chartData.push([moment(dataCall.yearMonth + '01 +0000', 'YYYYMMDD Z').valueOf(), Math.round(dataCall.total)])
            chartData.push({ x: dataCall.yearMonth, y: Math.round(dataCall.total)})
          })
          this.updateLineChartData(chartType, { dataCategory: dataCategory, min: this.customerInfo.earliest, max: this.customerInfo.latest, data: chartData })
        }
      }, // end of switch total
      updateLineChartData (chartType, overseasDataLineChartData) {
        const min = moment(overseasDataLineChartData.min + '01', 'YYYYMMDD')
        const max = moment(overseasDataLineChartData.max + '01', 'YYYYMMDD')
        this.chartType = chartType
        this.series = [{
          name: overseasDataLineChartData.dataCategory,
          data: overseasDataLineChartData.data,
        }]
      },
    },
  }
</script>

<style scoped>

</style>
