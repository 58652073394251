<template>
  <v-container
    id="usage"
    tag="section"
    fluid
    class="pa-0 ma-0"
    style="min-width:1366px;max-width:1920px;"
  >
    <template v-if="showSnDetail">
      <v-breadcrumbs :items="crumbs">
        <template v-slot:divider>
          <v-icon>mdi-chevron-right</v-icon>
        </template>
        <template v-slot:item="props">
          <v-chip
            :color="!props.item.disabled?'':'#FCDBE6'"
            :text-color="!props.item.disabled?'':'#EB3573'"
            @click="handleBreadCrumbClick(props.item.code)"
          >
            {{ props.item.text }}
          </v-chip>
        </template>
      </v-breadcrumbs>
      <service-number-page :sn-data="snFilter.sn" />
    </template>
    <template v-show="showHomePage">
      <v-card
        v-show="showHomePage"
        class="pa-0 ml-6 mr-6"
        style="height:78px"
      >
        <v-card-text>
        <v-row class="ml-4">
          <v-col
            lg="10"
            sm="10"
            class="pa-0 ma-0"
          >
            <v-tabs
              v-model="activeClass"
              slider-color="pink"
            >
              <v-tab
                v-for="n in classTypes"
                :key="n.id"
                :value="n.id"
                ripple
                @click="switchTab(n.code)"
              >
                {{ n.text }}
              </v-tab>
              <v-tab-item
                v-for="(n, index) in classTypes"
                :key="n.id"
              >
                <v-tabs
                  v-if="activeClass === index && n.hasChild == true"
                  v-model="activeTab[index]"
                  slider-color="pink"
                >
                  <v-tab
                    v-for="m in subClassTypes[n.id]"
                    :key="m.id"
                    :value="m.id"
                    ripple
                    :disabled="m.disabled"
                    @click="switchPage(m.code)"
                  >
                    <span
                      v-if="m.disabled"
                      style="color: white"
                    >{{ m.text }}</span>
                    <span v-else>{{ m.text }}</span>
                  </v-tab>
                </v-tabs>
              </v-tab-item>
            </v-tabs>
          </v-col>
          <v-col
            lg="2"
            sm="2"
            class="pa-0 ma-0"
          >
            <v-row
              justify="end"
            >
              <v-select
                v-model="filterYearMonth"
                :items="yearMonthReverse"
                item-text="label"
                item-value="yearMonth"
                label="Top ten by month"
                style="position:relative;top:24px;right:32px;max-width:150px"
                outlined
                dense
              />
            </v-row>
          </v-col>
        </v-row>
        </v-card-text>
      </v-card>
      <v-container fluid>
      <component
          v-bind:is="currentTabComponent"
          v-bind:data-type="filterDataType"
          v-bind:data-year-month="filterYearMonth"
          v-bind:data-category="filterCategory"
          v-show="showHomePage"
      />
      </v-container>
    </template>
  </v-container>
</template>

<script>
  import UsageBillTotal from '@/views/blitz/components/UsageBillTotal'
  import UsageNationalData from '@/views/blitz/components/UsageNationalData'
  import UsageNationalVoice from '@/views/blitz/components/UsageNationalVoice'
  import UsageInternationalData from '@/views/blitz/components/UsageInternationalData'
  import NoUsageBillTotal from '@/views/blitz/components/NoUsageBillTotal'
  import UsageSms from '@/views/blitz/components/UsageSMS'
  import ServiceNumberPage from '@/views/blitz/pages/ServiceNumberPage'
  import { mapGetters } from 'vuex'
  import store from '@/store'

  export default {
    name: 'Usage',
    components: {
      UsageBillTotal,
      UsageNationalData,
      UsageNationalVoice,
      UsageInternationalData,
      UsageSms,
      NoUsageBillTotal,
      ServiceNumberPage,
    },
    computed: {
      showHomePage () {
        // console.log('showHomePage() returning ' + !this.showSnDetail)
        return !this.showSnDetail
      },
      currentTabComponent () {
        if (this.filterCategory === 'BILL') {
          return 'UsageBillTotal'
        }
        if (this.filterCategory === 'DOMESTIC DATA') {
          return 'UsageNationalData'
        }
        if (this.filterCategory === 'DOMESTIC VOICE') {
          return 'UsageNationalVoice'
        }
        if (this.filterCategory === 'DOMESTIC') {
          return 'UsageNationalVoice'
        }
        if (this.filterCategory === 'OVERSEAS') {
          return 'UsageInternationalData'
        }
        if (this.filterCategory === 'NOUSAGE') {
          return 'NoUsageBillTotal'
        }
        // if (this.showCategory === 'SMS') {
        //   return 'UsageSms'
        // }
        return null
      },
      ...mapGetters('app', {
        customerInfo: 'customerInfo',
        snCount: 'snCount', //, ...
      }),
      ...mapGetters('usage', {
        snFilter: 'snFilter', //, ...
        defaultTopTenMonth: 'defaultTopTenMonth',
      }),
      filterVoice () {
        return this.filterDataType
      },
      yearMonthReverse () {
        return [...this.customerInfo.yearMonthSelectList].reverse()
      },
    },
    watch: {
      snFilter: {
        handler () {
          console.log('snFilter change to ' + this.snFilter)
          this.handleServiceNumberClick(this.snFilter)
        },
      },
      defaultTopTenMonth: {
        handler () {
          console.log('defaultTopTenMonth change to ', this.defaultTopTenMonth)
          // this.filterYearMonth = this.customerInfo.yearMonthSelectList[0]
          this.filterYearMonth = this.customerInfo.yearMonthSelectList[this.customerInfo.yearMonthSelectList.length - 1].yearMonth
        }
      }
    },
    data () {
      return {
        showSnDetail: false,
        classTypes: [
          { id: 0, text: 'Total', hasChild: false, code: { category: 'BILL', dataType: 'TOTAL' } },
          { id: 1, text: 'National Activity', hasChild: true },
          { id: 2, text: 'International Activity', hasChild: true },
          { id: 3, text: 'No Usage', hasChild: false, code: { category: 'NOUSAGE', dataType: 'TOTAL' } }],
        subClassTypes: [
          [],
          [
            { id: 199, text: '', disabled: true },
            { id: 200, text: 'Voice Call', code: { category: 'DOMESTIC VOICE', dataType: 'CALL' }, disabled: false },
            { id: 210, text: 'Voice Duration', code: { category: 'DOMESTIC VOICE', dataType: 'DURATION' }, disabled: false, },
            // { id: 220, text: 'SMS Call', code: { category: 'SMS', dataType: 'CALL' }, disabled: false, },
            { id: 220, text: 'SMS Call', code: { category: 'DOMESTIC', dataType: 'SMS' }, disabled: false, }, // using DOMESTIC VOICE so the tab page stays on DOMESTIC VOICE
            {
              id: 230,
              text: 'Data Usage (Mb)',
              code: { category: 'DOMESTIC DATA', dataType: 'USAGE' },
              disabled: false,
            },
            {
              id: 240,
              text: 'Data Excess ($)',
              code: { category: 'DOMESTIC DATA', dataType: 'EXCESS' },
              disabled: false,
            }],
          [
            { id: 299, text: 'Total', disabled: true },
            { id: 300, text: 'National Activity', disabled: true },
            {
              id: 310,
              text: 'Data Usage (Mb)',
              code: { category: 'OVERSEAS', dataType: 'USAGE' },
              disabled: false,
            },
            {
              id: 320,
              text: 'Data Excess ($)',
              code: { category: 'OVERSEAS', dataType: 'EXCESS' },
              disabled: false,
            }],

        ],
        activeClass: 0,
        activeTab: [0, 1, 3], // it is the tab that ia active instead of the item id!!
        tabs: 0,
        showCategory: 'BILL',
        filterDataType: 'TOTAL',
        filterCategory: 'BILL',
        filterYearMonth: null,
        crumbs: [
          {
            text: 'Usage Summary',
            disabled: false,
            code: 'usageSummary',
            href: 'breadcrumbs_dashboard',
          },
        ],
        table_crumbs: [
          {
            text: 'Usage Summary',
            disabled: false,
            code: 'usageSummary',
            href: 'breadcrumbs_dashboard',
          },
        ],
        sn_crumbs: [
          {
            text: 'Usage Summary',
            disabled: false,
            code: 'usageSummary',
            href: 'breadcrumbs_dashboard',
          },
          {
            text: 'Service Detail',
            code: 'serviceNumber',
            disabled: true,
            href: 'breadcrumbs_dashboard',
          },
        ],
      }
    },
    mounted () {
      this.$gtag.pageview({
        page_path: '/usage',
      })
      // console.log('public path is ' + this.publicPath)
    },
    methods: {
      handleServiceNumberClick (snFilter) {
        this.crumbs = this.sn_crumbs
        this.showSnDetail = true
      },
      handleBreadCrumbClick (code) {
        console.log('handleBreadCrumbClick value is ' + code)
        this.showSnDetail = false
        this.crumbs = this.table_crumbs
      },
      switchTab (code) {
        if (code) {
          // this.$gtag.event('usage', { switchTab: code })
          this.$gtag.event('usage', {
            event_category: code.dataType,
            event_label: code.category,
          })
          // console.log('switchTab to ', code)
          store.dispatch('usage/refreshSelectedTab', { category: code.category, dataType: code.dataType })
          //if (code.category === 'BILL') {
            this.showSnDetail = false
            // this.showCategory = code.category
            this.filterDataType = code.dataType
            this.filterCategory = code.category
          //}
        }
      },
      switchPage (code) { // where code is {category: "NATIONAL VOICE", dataType: "CALL"}
        // this.$gtag.event('usage', { switchPage: code })
        this.$gtag.event('usage', {
          event_category: code.category,
          event_label: code.dataType,
        })
        // console.log('switchPage to ', code)
        this.showSnDetail = false
        // this.showCategory = code.category
        this.filterDataType = code.dataType
        this.filterCategory = code.category
        store.dispatch('usage/refreshSelectedTab', { category: this.filterCategory, dataType: this.filterDataType })
        // this.filterYearMonth = null // reset the filter when a page is switch
      },
    },
  }
</script>

<style scoped>
</style>
