<template>
  <v-container
    id="usageNationalVoice"
    fluid
    tag="section"
    class="pt-0 pb-0"
  >
    <v-row
      class="pt-0 pb-0"
    >
      <v-col
        cols="12"
        sm="6"
        lg="8"
        class="pt-0"
      >
          <usage12-months-line-chart
            :data-category="dataCategory"
            :data-year-month="dataYearMonth"
          />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        lg="4"
        class="pt-0"
      >
          <usage-top-ten-pie-chart
            :data-year-month="dataYearMonth"
            :data-category="dataCategory"
            :data-type="dataType"
          />
      </v-col>
    </v-row>
    <v-row
      class="pt-0 pb-0 mt-5"
    >
      <v-col
        cols="12"
        sm="12"
        lg="12"
        class="pt-0 mt-0"
      >
        <v-tabs-items
          v-model="tabs"
          style="background-color:#F5F5F5"
        >
          <v-tab-item>
            <usage-data-table
              :data-category="dataCategory"
              data-type="CALL"
              :data-year-month="dataYearMonth"
            />
          </v-tab-item>
          <v-tab-item>
            <usage-data-table
              :data-category="dataCategory"
              data-type="DURATION"
              :data-year-month="dataYearMonth"
            />
          </v-tab-item>
          <v-tab-item>
            <usage-data-table
              :data-category="dataCategory"
              data-type="SMS"
              :data-year-month="dataYearMonth"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'
  import Usage12MonthsLineChart from '@/views/blitz/components/apex/UsageVoice12MonthsLineChart'
  import UsageTopTenPieChart from '@/views/blitz/components/apex/UsageVoiceTopTenPieChart'
  import UsageDataTable from '@/views/blitz/components/UsageDataTable'
  import store from '@/store'

  export default {
    name: 'UsageNationalVoice',
    components: {
      Usage12MonthsLineChart,
      UsageTopTenPieChart,
      UsageDataTable,
    },
    props: {
      dataType: {
        type: String,
      },
      dataYearMonth: {
        type: Number,
      },
      dataCategory: {// get DOMESTIC VOICE or DOMESTIC SMS
        type: String,
      }
    },
    computed: {
      // Following will only add getters..
      // How to add setter ???

      ...mapGetters('app', {
        customerInfo: 'customerInfo',
        snCount: 'snCount', //, ...
      }),
      ...mapGetters('usage', {
        domesticVoiceCategory: 'domesticVoiceCategory',
      }),
    },
    watch: {
      domesticVoiceCategory: {
        handler () {
          console.log('watching domesticVoiceCategory property changed to ', this.domesticVoiceCategory)
        },
      },
      dataType: {
        handler () {
          console.log('watching dataType property changed to ' + this.dataType + ' now called switchCategory')
          this.switchCategory(this.dataType)
        },
      },
    },
    data () {
      return {
        refreshData: true,
        tabs: 0,
      }
    },
    mounted () {
      console.log('mounted category ' + this.dataCategory + ' dataType is ' + this.dataType)
      if (this.dataType === 'CALL') {
        this.tabs = 0
      } else if (this.dataType === 'DURATION') {
        this.tabs = 1
      } else { // assume this is SMS
        this.tabs = 2
      }
      if (this.refreshData) {
        // the UsageVoice12MonthsLineChart listens to this event to update the line chart
        store.dispatch('usage/refreshDomesticVoiceCategory', { category: this.dataCategory, dataType: this.dataType })
        this.refreshData = false
      }
    },
    methods: {
      switchCategory (dataType) {
        console.log('switchCategory ' + this.dataCategory + ' switching dataType ' + this.dataType + ' passed data type is ', dataType) // category to CALL, DURATION, DATA, EXCESS
        if (this.dataType === 'CALL') {
          this.tabs = 0
        }
        if (this.dataType === 'DURATION') {
          this.tabs = 1
        }
        if (this.dataType === 'SMS') {
          this.tabs = 2
        }
        // this.dataCategory = dataType
        // the UsageVoice12MonthsLineChart listens to this event to update the line chart
        // ths UsageVoiceToptenPieChart listens to this event
        store.dispatch('usage/refreshDomesticVoiceCategory', { category: this.dataCategory, dataType: this.dataType }) // this is now call in Usage.vue to refresh the line chart and top ten
      },
    },
  }

</script>

<style scoped>

</style>
