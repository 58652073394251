<template>
  <v-card
    height="100%"
    align="center"
  >
    <v-card-title class="pl-6 pt-4 font-weight-light">
      12mth National Data Charges ($)
    </v-card-title>
    <apexchart
      type="bar"
      height="330"
      :options="chartOptions"
      :series="series"
    />
  </v-card>
</template>

<script>
  import moment from 'moment'
  import { mapGetters } from 'vuex'
  import { findLast12MonthsDomesticDataTotal } from '@/api/PouchDaoReport'
  import Utils from '@/api/util'

  export default {
    name: 'UsageData12MonthsLineChart',
    props: {
      dataYearMonth: {
        type: Number,
      },
    },
    computed: {
      ...mapGetters('app', {
        customerInfo: 'customerInfo',
        snCount: 'snCount', //, ...
      }),
      ...mapGetters('usage', {
        domesticDataCategory: 'domesticDataCategory',
        refreshRequired: 'refreshRequired',
      }),
    },
    data () {
      return {
        refreshData: true,
        cacheChartData: [],
        series: [{
          data: [],
        }],
        chartOptions: {
          chart: {
            stacked: false,
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          fill: {
            type: 'solid',
            opacity: [0.35, 1],
          },
          markers: {
            size: 0,
            style: 'hollow',
          },
          xaxis: {
            labels: {
              formatter: function (value) {
                const dateValue = moment(value + '01 +0000', 'YYYYMMDD Z')
                return dateValue.format('MMM YY')
              },
              style: {
                colors: '#4D4D4D',
                fontSize: '11px',
              },
            },
            axisBorder: {
              show: true,
              color: '#B2B2B2',
            },
          },
          yaxis: [
            {
              seriesName: 'Data Excess',
              labels: {
                style: {
                  color: '#4D4D4D',
                  fontSize: '11px',
                },
                formatter: function (value) {
                  return Utils.formatCurrency(value)
                },
              },
              title: {
                text: 'Data Excess ($)',
                offsetX: 0,
                style: {
                  color: '#959494',
                  fontWeight: 400,
                  fontFamily: 'Roboto, sans-serif',
                  fontSize: '11px',
                },
              },
            },
          ],

          tooltip: {
            x: {
              format: 'MMM yyyy',
            },
          },
          grid: {
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5,
            },
          },
        },
      }
    },
    watch: {
      domesticDataCategory: {
        handler () {
          if (this.cacheChartData) {
            this.switch12MonthsTotal(this.domesticDataCategory)
          }
        },
      },
      dataYearMonth: {
        handler () {
          console.log('watching dataYearMonth changed to ' + this.dataYearMonth)
          this.updateAnnotationOnChart(this.dataYearMonth)
        },
      },
    },
    mounted () {
      if (this.refreshData) {
        findLast12MonthsDomesticDataTotal(this.customerInfo.earliest, this.customerInfo.latest).then(result => {
          console.log('find12MonthsDomesticDataTotalCall data total call completed')
          this.cacheChartData = result // result contains {totalCall , totalDuration, totalUsage}
          this.minYearMonth = this.customerInfo.earliest
          this.maxYearMonth = this.customerInfo.latest
          this.switch12MonthsTotal(this.domesticDataCategory)
          this.refreshData = false
        })
      }
      // if (this.refreshData) {
      //   find12MonthsDomesticDataTotalCall(this.customerInfo.earliest, this.customerInfo.latest).then(result => {
      //     console.log('find12MonthsDomesticDataTotalCall data total call completed')
      //     this.cacheChartData = result // result contains {totalCall , totalDuration, totalUsage}
      //     this.minYearMonth = this.customerInfo.earliest
      //     this.maxYearMonth = this.customerInfo.latest
      //     this.switch12MonthsTotal(this.domesticDataCategory)
      //     this.refreshData = false
      //   })
      // }
    },
    methods: {
      updateAnnotationOnChart (yearMonth) {
        console.log('updateAnnotationOnChart yearMonth is ' + yearMonth)
        const dateValue = moment(yearMonth + '01 +0000', 'YYYYMMDD Z')
        this.chartOptions = {
          annotations: {
            xaxis: [
              {
                // in a datetime series, the x value should be a timestamp, just like it is generated below
                // x: moment(yearMonth + '01 +0000', 'YYYYMMDD Z').valueOf(),
                // x: dateValue.format('MMM YY'),
                x: yearMonth + '',
                strokeDashArray: 0,
                borderColor: '#775DD0',
                label: {
                  borderColor: '#775DD0',
                  style: {
                    color: '#fff',
                    background: '#775DD0',
                  },
                  text: 'Top ten',
                },
              },
            ],
          },
        }
      },
      switch12MonthsTotal (dataCategory) {
        console.log('switch12MonthsTotal ' + dataCategory)
        if (dataCategory === 'CALL') {
          const chartData = []
          this.cacheChartData.totalCall.forEach(dataCall => {
            // console.log('year month ' + dataCall.yearMonth + ' call total ' + dataCall.call)
            // chartData.push([moment(dataCall.yearMonth + '01 +0000', 'YYYYMMDD Z').valueOf(), dataCall.total])
            chartData.push({x: dataCall.yearMonth, y: Math.round(dataCall.total)})
          })
          this.updateLineChartData({ dataCategory: dataCategory, min: this.customerInfo.earliest, max: this.customerInfo.latest, data: chartData })
        }
        if (dataCategory === 'DURATION') {
          const chartData = []
          this.cacheChartData.totalDuration.forEach(dataCall => {
            // console.log('year month ' + dataCall.yearMonth + ' duration total ' + dataCall.duration)
            // chartData.push([moment(dataCall.yearMonth + '01 +0000', 'YYYYMMDD Z').valueOf(), Math.round(dataCall.total)])
            chartData.push({x: dataCall.yearMonth, y: Math.round(dataCall.total)})
          })
          this.updateLineChartData({ dataCategory: dataCategory, min: this.customerInfo.earliest, max: this.customerInfo.latest, data: chartData })
        }
        if (dataCategory === 'USAGE') {
          const chartData = []
          this.cacheChartData.totalUsage.forEach(dataCall => {
            // console.log('year month ' + dataCall.yearMonth + ' data total ' + dataCall.usage)
            chartData.push({x: dataCall.yearMonth, y: Math.round(dataCall.total)})
          })
          this.updateLineChartData({ dataCategory: dataCategory, min: this.customerInfo.earliest, max: this.customerInfo.latest, data: chartData })
        }
        if (dataCategory === 'EXCESS') {
          const chartData = []
          this.cacheChartData.totalExcess.forEach(dataCall => {
            // console.log('year month ' + dataCall.yearMonth + ' excess total ' + dataCall.excess)
            // chartData.push([moment(dataCall.yearMonth + '01 +0000', 'YYYYMMDD Z').valueOf(), Math.round(dataCall.total)])
            chartData.push({x: dataCall.yearMonth, y: Math.round(dataCall.total)})
          })
          this.updateLineChartData({ dataCategory: dataCategory, min: this.customerInfo.earliest, max: this.customerInfo.latest, data: chartData })
        }
      }, // end of switch total
      updateLineChartData (domesticDataLineChartData) {
        console.info('updateLineChartData excess data is ', domesticDataLineChartData.data)
        const min = moment(domesticDataLineChartData.min + '01 +0000', 'YYYYMMDD Z').subtract(5, 'd')
        const max = moment(domesticDataLineChartData.max + '01 +0000', 'YYYYMMDD Z').add(5, 'd')
        console.info('updateLineChartData ' + domesticDataLineChartData.dataCategory + ' min ' + min.valueOf() + ' max ' + max)
        // this.chartOptions = {
        //   xaxis: {
        //     min: min,
        //     max: max,
        //     type: 'datetime',
        //     tickAmount: 6,
        //   },
        // }
        this.series = [{
          name: domesticDataLineChartData.dataCategory,
          data: domesticDataLineChartData.data,
        }]
      },
    },
  }
</script>

<style scoped>

</style>
