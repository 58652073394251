<template>
  <v-card height="100%" align="center">
    <v-card-title class="pl-6 pt-4 font-weight-light">
      12mth Total Charges ($)
    </v-card-title>
    <apexchart
      type="bar"
      :options="chartOptions"
      :series="series"
      height="330px"
      class="pl-1 pr-1"
    />
  </v-card>
</template>

<script>
import Utils from "@/api/util";
import moment from "moment";
import { mapGetters } from "vuex";
import { findLast12MonthsBillTotal } from "@/api/PouchDaoReport";

export default {
  name: "UsageBillTotal12MonthsLineChart",
  props: {
    dataCategory: {
      type: String,
      default: "BILL",
    },
    dataType: {
      type: String,
      default: "TOTAL",
    },
    dataYearMonth: {
      type: Number,
    },
  },
  data() {
    return {
      // minYearMonth: '201701',
      // maxYearMonth: '201911',
      refreshData: true,
      // cacheChartData: [],
      series: [
        {
          // as below
        },
      ],
      chartOptions: {
        colors: ["#E91E63"],
        chart: {
          stacked: false,
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            type: "vertical",
            shadeIntensity: 0.1,
            gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
            inverseColors: true,
            opacityFrom: 0.8,
            opacityTo: 0.7,
            stops: [0, 100],
            colorStops: [],
          },
        },
        stroke: {
          curve: "straight",
        },
        markers: {
          size: 0,
          style: "hollow",
        },
        xaxis: {
          type: "datetime",
          tickAmount: 6,
          // labels: {
          /**
           * Allows users to apply a custom formatter function to xaxis labels.
           *
           * @param { String } value - The default value generated
           * @param { Number } timestamp - In a datetime series, this is the raw timestamp
           * @param { index } index of the tick / currently executing iteration in xaxis labels array
           */
          // formatter: function (value, timestamp, index) {
          //   return moment(new Date(timestamp)).format('MMM YYYY')
          // },
          // },
        },
        plotOptions: {
          bar: {
            columnWidth: "50%",
          },
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return Utils.formatCurrency(value);
            },
          },
        },
        tooltip: {
          x: {
            format: "MMM yyyy",
          },
          y: {
            format: {
              formatter: function (value) {
                return (
                  "$" + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                );
                // return new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'AUD'  }).format(value)
              },
            },
            title: {
              formatter: (seriesName) => seriesName,
            },
          },
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters("app", {
      customerInfo: "customerInfo",
      snCount: "snCount", //, ...
    }),
    ...mapGetters("usage", {
      refreshRequired: "refreshRequired",
      selectedTab: "selectedTab",
    }),
  },
  watch: {
    dataYearMonth: {
      handler() {
        console.log("watching dataYearMonth changed to " + this.dataYearMonth);
        this.updateAnnotationOnChart(this.dataYearMonth);
      },
    },
    selectedTab: {
      handler() {
        console.log("selected tab", this.selectedTab);
        if (this.selectedTab === this.dataCategory) {
          this.switch12MonthsTotal(this.dataCategory);
        }
      },
    },
  },
  mounted() {
    if (this.refreshData) {
      findLast12MonthsBillTotal(
        this.dataCategory,
        this.dataType,
        this.customerInfo.earliest,
        this.customerInfo.latest
      ).then((result) => {
        // console.log('mounted find12MonthsBillTotal data total call completed')
        this.cacheChartData = result.billList;
        // console.log('findLast12MonthsBillTotal mounted', result.billList)
        // let labels = []
        let chartData = [];
        this.cacheChartData.forEach((dataCall) => {
          console.log(
            "year month " + dataCall.yearMonth + " call total " + dataCall.total
          );
          // labels.push(dataCall.yearMonth)
          // chartData.push(dataCall.call)
          chartData.push([
            moment(dataCall.yearMonth + "01 +0000", "YYYYMMDD Z").valueOf(),
            dataCall.total,
          ]);
        });
        // console.log('billlist chartData', chartData)
        this.minYearMonth = this.customerInfo.earliest;
        this.maxYearMonth = this.customerInfo.latest;
        // store.dispatch('usage/updateLineChartData', { dataCategory: this.dataCategory, min: this.customerInfo.earliest, max: this.customerInfo.latest, data: chartData })
        this.switch12MonthsTotal(this.dataCategory);
        // store.dispatch('usage/updateLineChartxAxisLabel', labels)
        this.refreshData = false;
      });
    }
  },
  methods: {
    updateAnnotationOnChart(yearMonth) {
      console.log("updateAnnotationOnChart yearMonth is " + yearMonth);
      this.chartOptions = {
        annotations: {
          xaxis: [
            {
              // in a datetime series, the x value should be a timestamp, just like it is generated below
              x: moment(yearMonth + "01 +0000", "YYYYMMDD Z").valueOf(),
              // x: yearMonth + '',
              strokeDashArray: 0,
              borderColor: "#775DD0",
              label: {
                borderColor: "#775DD0",
                style: {
                  color: "#fff",
                  background: "#775DD0",
                },
                text: "Top ten",
              },
            },
          ],
        },
      };
    },
    switch12MonthsTotal(dataCategory) {
      console.log("switch12MonthsTotal", dataCategory);
      if (dataCategory === this.dataCategory) {
        let chartData = [];
        this.cacheChartData.forEach((dataCall) => {
          console.log(
            "year month " + dataCall.yearMonth + " call total " + dataCall.total
          );
          // chartData.push(dataCall.call)
          chartData.push({
            x: moment(dataCall.yearMonth + "01 +0000", "YYYYMMDD Z").valueOf(),
            y: dataCall.total,
          });
        });
        this.updateLineChartData({
          dataCategory: dataCategory,
          min: this.customerInfo.earliest,
          max: this.customerInfo.latest,
          data: chartData,
        });
      }
    }, // end of switch total
    updateLineChartData(domesticVoiceLineChartData) {
      console.log("updateLineChartData", domesticVoiceLineChartData);
      const min = moment(
        domesticVoiceLineChartData.min + "01 +0000",
        "YYYYMMDD Z"
      ).subtract(5, "d");
      const max = moment(
        domesticVoiceLineChartData.max + "01 +0000",
        "YYYYMMDD Z"
      ).add(5, "d");
      // this.chartOptions = {
      //   xaxis: {
      //     min: min.valueOf(),
      //     max: max.valueOf(),
      //   },
      // }
      this.series = [
        {
          name: domesticVoiceLineChartData.dataCategory,
          data: domesticVoiceLineChartData.data,
        },
      ];
    },
  },
};
</script>

<style scoped></style>
