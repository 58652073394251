<template>
  <v-container
    id="usageInternationalData"
    fluid
    tag="section"
    class="pt-0 pb-0"
  >
    <v-row
      class="pt-0 pb-0"
    >
      <v-col
        cols="12"
        sm="6"
        lg="8"
        class="pt-0"
      >
          <usage12-months-line-chart
            v-if="dataCategory == 'USAGE'"
            :data-year-month="dataYearMonth"
          />
          <usage12-months-excess-line-chart
            v-if="dataCategory == 'EXCESS'"
            :data-year-month="dataYearMonth"
          />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        lg="4"
        class="pt-0"
      >
          <usage-top-ten-pie-chart :data-year-month="dataYearMonth" />
      </v-col>
    </v-row>
    <v-row class="pt-0 pb-0 mt-5">
      <v-col
        cols="12"
        sm="12"
        lg="12"
        class="pt-0 mt-0"
      >
        <v-tabs-items
          v-model="tabs"
          style="background-color:#F5F5F5"
        >
          <v-tab-item>
            <usage-data-table
              data-category="OVERSEAS DATA"
              data-type="CALL"
              :data-year-month="dataYearMonth"
            />
          </v-tab-item>
          <v-tab-item>
            <usage-data-table
              data-category="OVERSEAS DATA"
              data-type="DURATION"
              :data-year-month="dataYearMonth"
            />
          </v-tab-item>
          <v-tab-item>
            <usage-data-table
              data-category="OVERSEAS DATA"
              data-type="USAGE"
              :data-year-month="dataYearMonth"
            />
          </v-tab-item>
          <v-tab-item>
            <usage-data-table
              data-category="OVERSEAS DATA"
              data-type="EXCESS"
              :data-year-month="dataYearMonth"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'
  import Usage12MonthsLineChart from '@/views/blitz/components/apex/UsageOverseasData12MonthsLineChart'
  import Usage12MonthsExcessLineChart from '@/views/blitz/components/apex/UsageOverseasExcess12MonthsLineChart'
  import UsageTopTenPieChart from '@/views/blitz/components/apex/UsageOverseasDataTopTenPieChart'
  import UsageDataTable from '@/views/blitz/components/UsageDataTable'
  import store from '@/store'

  export default {
    name: 'UsageInternationalData',
    components: {
      Usage12MonthsLineChart,
      Usage12MonthsExcessLineChart,
      UsageTopTenPieChart,
      UsageDataTable,
    },
    props: {
      dataType: {
        type: String,
        default: 'USAGE',
      },
      dataYearMonth: {
        type: Number,
      },

    },
    computed: {
      // Following will only add getters..
      // How to add setter ???

      ...mapGetters('app', {
        customerInfo: 'customerInfo',
        snCount: 'snCount', //, ...
      }),
    },
    watch: {
      dataType: {
        handler () {
          console.log('watching dataType property changed to ' + this.dataType)
          this.switchCategory(this.dataType)
          if (this.dataType === 'USAGE') {
            this.tabs = 2
          }
          if (this.dataType === 'EXCESS') {
            this.tabs = 3
          }
        },
      },
    },

    data () {
      return {
        refreshData: true,
        tabs: 2,
        dataCategory: 'USAGE',
      }
    },
    mounted () {
      if (this.dataType === 'USAGE') {
        this.tabs = 2
      }
      if (this.dataType === 'EXCESS') {
        this.tabs = 3
      }
      if (this.refreshData) {
        store.dispatch('usage/refreshOverseasDataCategory', this.dataType)
        this.refreshData = false
      }
    },
    methods: {
      switchCategory (dataType) {
        console.log('switching dataType to ' + dataType) // dataType to CALL, USAGE, DATA, EXCESS
        this.dataCategory = dataType
        store.dispatch('usage/refreshOverseasDataCategory', this.dataType)
      },
    },
  }

</script>

<style scoped>

</style>
