<template>
  <v-card
    height="100%"
    align="center"
  >
    <v-card-title class="pl-6 pt-4 font-weight-light">12mth Total SMS Call Count</v-card-title>
    <apexchart
      type="area"
      height="330"
      :options="chartOptions"
      :series="series"
    />
  </v-card>
</template>

<script>
  import moment from 'moment'
  import { mapGetters } from 'vuex'
  import { find12MonthsSMSTotalCall } from '@/api/PouchDaoReport'
  import Utils from '@/api/util'

  export default {
    name: 'UsageSMS12MonthsLineChart',
    props: {
      dataYearMonth: {
        type: Number,
      },
    },
    data () {
      return {
        minYearMonth: '201701',
        maxYearMonth: '201911',
        refreshData: true,
        cacheChartData: [],
        series: [{
          data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
        }],
        chartOptions: {
          colors: ['#1ADC35'],
          chart: {
            stacked: false,
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              type: 'vertical',
              shadeIntensity: 0.1,
              gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
              inverseColors: true,
              opacityFrom: 0.7,
              opacityTo: 0.3,
              stops: [0, 100],
              colorStops: [],
            },
          },
          stroke: {
            curve: 'straight',
            width: 2,
          },
          plotOptions: {
            bar: {
              columnWidth: '30%',
            },
          },
          markers: {
            size: 5,
            style: 'hollow',
            strokeColors: '#fff',
            strokeWidth: 2,
            strokeOpacity: 0.8,
            strokeDashArray: 0,
            fillOpacity: 1,
            sizeOffset: 1,
          },
          xaxis: {
            type: 'datetime',
            tickAmount: 6,
            // labels: {
            /**
             * Allows users to apply a custom formatter function to xaxis labels.
             *
             * @param { String } value - The default value generated
             * @param { Number } timestamp - In a datetime series, this is the raw timestamp
             * @param { index } index of the tick / currently executing iteration in xaxis labels array
             */
            // formatter: function (value, timestamp, index) {
            //   return moment(new Date(timestamp)).format('MMM YYYY')
            // },
            // },
          },
          yaxis: [
            {
              seriesName: 'Sms',
              labels: {
                style: {
                  color: '#4D4D4D',
                  fontSize: '11px',
                },
                formatter: function (value) {
                  return Utils.readableBytes(value)
                },
              },
              title: {
                text: 'Sms',
                offsetX: 0,
                style: {
                  color: '#959494',
                  fontWeight: 400,
                  fontFamily: 'Roboto, sans-serif',
                  fontSize: '11px',
                },
              },
            },
          ],
          tooltip: {
            x: {
              format: 'MMM yyyy',
            },
          },
          grid: {
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5,
            },
          },
        },
      }
    },
    computed: {
      ...mapGetters('app', {
        customerInfo: 'customerInfo',
        snCount: 'snCount', //, ...
      }),
      ...mapGetters('usage', {
        refreshRequired: 'refreshRequired',
      }),
    },
    watch: {
      dataYearMonth: {
        handler () {
          console.log('watching dataYearMonth changed to ' + this.dataYearMonth)
          this.updateAnnotationOnChart(this.dataYearMonth)
        },
      },

    },
    mounted () {
      if (this.refreshData) {
        find12MonthsSMSTotalCall(this.customerInfo.earliest, this.customerInfo.latest).then(result => {
          console.log('find12MonthsSMSTotalCall data total call completed')
          this.cacheChartData = result.callCountList
          let chartData = []
          this.cacheChartData.forEach(dataCall => {
            chartData.push([moment(dataCall.yearMonth + '01 +0000', 'YYYYMMDD Z').valueOf(), dataCall.call])
          })
          // console.log(chartData)
          this.minYearMonth = this.customerInfo.earliest
          this.maxYearMonth = this.customerInfo.latest
          // store.dispatch('usage/updateLineChartData', { dataCategory: this.dataCategory, min: this.customerInfo.earliest, max: this.customerInfo.latest, data: chartData })
          this.switch12MonthsTotal('SMS')
          // store.dispatch('usage/updateLineChartxAxisLabel', labels)
          this.refreshData = false
        })
      }
    },
    methods: {
      updateAnnotationOnChart (yearMonth) {
        console.log('updateAnnotationOnChart yearMonth is ' + yearMonth)
        this.chartOptions = {
          annotations: {
            xaxis: [
              {
                // in a datetime series, the x value should be a timestamp, just like it is generated below
                // x: new Date(yearMonth+'01').getTime(),
                x: moment(yearMonth + '01 +0000', 'YYYYMMDD Z').valueOf(),
                strokeDashArray: 0,
                borderColor: '#775DD0',
                label: {
                  borderColor: '#775DD0',
                  style: {
                    color: '#fff',
                    background: '#775DD0',
                  },
                  text: 'Top ten',
                },
              },
            ],
          },
        }
      },

      switch12MonthsTotal (dataCategory) {
        if (dataCategory === 'SMS') {
          let chartData = []
          this.cacheChartData.forEach(dataCall => {
            // chartData.push([moment(dataCall.yearMonth + '01 +0000', 'YYYYMMDD Z').valueOf(), dataCall.sms])
            chartData.push({ x: dataCall.yearMonth, y: dataCall.sms})
          })
          this.updateLineChartData({
            dataCategory: dataCategory,
            min: this.customerInfo.earliest,
            max: this.customerInfo.latest,
            data: chartData,
          })
        }
      }, // end of switch total
      updateLineChartData (domesticVoiceLineChartData) {
        const min = moment(domesticVoiceLineChartData.min + '01 +0000', 'YYYYMMDD Z').subtract(5, 'd')
        const max = moment(domesticVoiceLineChartData.max + '01 +0000', 'YYYYMMDD Z').add(5, 'day')
        this.chartOptions = {
          xaxis: {
            min: min.valueOf(),
            max: max.valueOf(),
          },
        }
        this.series = [{
          name: domesticVoiceLineChartData.dataCategory,
          data: domesticVoiceLineChartData.data,
        }]
      },
    },
  }
</script>

<style scoped>

</style>
