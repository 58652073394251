import moment from 'moment'
import { isEmpty } from 'lodash'
import {
  getSnUsageMonthDb,
  getSnDb,
  getEquipmentSummaryDb,
  getCustomerInfo,
  getSnCustomerInfoDb,
} from './PouchDao'

import {
  createMonthRange,
  find12MonthsTotal
} from './PouchDaoReport'

/**
 * return {callTotal: result}
 * @param startYearMonth
 * @param endYearMonth
 * @returns {Promise<{callTotal: *}>}
 */
async function findLast12MonthsDomesticVoiceAndSmsTotal (startYearMonth, endYearMonth) {
  // group the billtotal by months and get the total...
  console.log('findLast12MonthsDomesticVoiceTotal started for ' + startYearMonth + ' to ' + endYearMonth)
  return createMonthRange(startYearMonth, endYearMonth).then((result) => {
    const monthList = result // array of ['201801, '201802'...]
    let totalCall = find12MonthsTotal('DOMESTIC VOICE', 'CALL', monthList)
    let totalDuration = find12MonthsTotal('DOMESTIC VOICE', 'DURATION', monthList)
    let totalSms = find12MonthsTotal('DOMESTIC SMS', null, monthList)
    return Promise.all([totalCall, totalDuration, totalSms]).then(([totalCall, totalDuration, totalSms]) => {
      console.log('findLast12MonthsDomesticVoiceTotal completed ' + startYearMonth + ' ending ' + endYearMonth)
      return {totalCall: totalCall, totalDuration: totalDuration, totalSms: totalSms}
    })
  })
}
/*
async function findLast12MonthsDomesticSMSTotal (startYearMonth, endYearMonth) {
  // group the billtotal by months and get the total...
  console.log('findLast12MonthsSMSTotal started for ' + startYearMonth + ' to ' + endYearMonth)
  return createMonthRange(startYearMonth, endYearMonth).then((result) => {
    let dataTotalMonths = []
    result.forEach((yearMonth) => {
      let dataTotalMonthPromise = findSMSTotalForMonth(yearMonth).then((callCountListObject) => {
        // console.log(callCountListObject.callCountList)
        return callCountListObject.callCountList[0]
      })
      dataTotalMonths.push(dataTotalMonthPromise)
    })
    return Promise.all(dataTotalMonths).then((result) => {
      let dataTotal = []
      result.forEach((dataTotalMonth) => {
        // console.log(dataTotalMonth)
        dataTotal.push(dataTotalMonth)
      })
      return { callCountList: dataTotal }
    })
  })
}
async function findSMSTotalForMonth (yearMonth) {
  // console.log('findSMSTotalForMonth for ' + yearMonth + ' started')
  // note the snmonth usage key is DOMESTIC-SMS-01202202
  return getSnUsageMonthDb().then((snUsageMonthDb) => {
    return snUsageMonthDb.allDocs({
      startkey: 'DOMESTIC-SMS',
      endkey: 'DOMESTIC-SMS' + '\ufff0',
      include_docs: true,
    })
  }).then(function (result) {
    let dataUsages = []
    result.rows.forEach((row) => {
      dataUsages.push({ yearMonth: yearMonth, sms: row.doc[yearMonth], snCount: 1 })
    })
    const callCountList = [
      ...dataUsages.reduce(
        (map, item) => {
          const { yearMonth: key } = item
          const prev = map.get(key)
          if (prev) {
            prev.sms += item.sms
            prev.snCount += 1
          } else {
            map.set(key, Object.assign({}, item))
          }
          return map
        },
        new Map(),
      ).values(),
    ]
    return { callCountList: callCountList }
  })
}
*/

export {
  findLast12MonthsDomesticVoiceAndSmsTotal
}
