<template>
  <v-card
    height="100%"
    align="center"
  >
    <v-card-title class="pl-6 pt-4 font-weight-light">
      {{lineChartTitle}}
    </v-card-title>
    <apexchart
      type="area"
      height="330"
      :options="chartOptions"
      :series="series"
    />
  </v-card>
</template>

<script>
  import moment from 'moment'
  import { mapGetters } from 'vuex'
  // import { findLast12MonthsDomesticVoiceTotal } from '@/api/PouchDaoReport'
  import { findLast12MonthsDomesticVoiceAndSmsTotal } from '@/api/UsageDaoReport'
  import Utils from '@/api/util'

  export default {
    name: 'UsageVoice12MonthsLineChart',
    props: {
      dataCategory: { // get DOMESTIC VOICE or DOMESTIC SMS
        type: String,
      },
      dataYearMonth: {
        type: Number,
      },
    },
    data () {
      return {
        // minYearMonth: '201701',
        // maxYearMonth: '201911',
        refreshData: true,
        customYaxisCall: [
          {
            seriesName: 'Voice calls',
            labels: {
              style: {
                color: '#4D4D4D',
                fontSize: '11px',
              },
              formatter: function (value) {
                return Utils.formatNumber(value)
              },
            },
            title: {
              text: 'Voice Call (count)',
              offsetX: 0,
              style: {
                color: '#959494',
                fontWeight: 400,
                fontFamily: 'Roboto, sans-serif',
                fontSize: '11px',
              },
            },
          },
        ],
        customYaxisDuration: [
          {
            seriesName: 'Voice Duration',
            labels: {
              style: {
                color: '#4D4D4D',
                fontSize: '11px',
              },
              formatter: function (value) {
                return Utils.convertMinutesToDhms(value)
              },
            },
            title: {
              text: 'Voice Duration (Minutes)',
              offsetX: 0,
              style: {
                color: '#959494',
                fontWeight: 400,
                fontFamily: 'Roboto, sans-serif',
                fontSize: '11px',
              },
            },
          },
        ],
        customYaxisSms: [
          {
            seriesName: 'SMS',
            labels: {
              style: {
                color: '#4D4D4D',
                fontSize: '11px',
              },
              formatter: function (value) {
                return Utils.formatNumber(value)
              },
            },
            title: {
              text: 'SMS (count)',
              offsetX: 0,
              style: {
                color: '#959494',
                fontWeight: 400,
                fontFamily: 'Roboto, sans-serif',
                fontSize: '11px',
              },
            },
          },
        ],

        cacheChartData: [],
        series: [{
          data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
        }],
        chartOptions: {
          colors: ['#FBAA39', '#D538FC'],
          chart: {
            // height: 150,
            // type: 'bar',
            // zoom: {
            //   enabled: false,
            // },
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              type: 'vertical',
              shadeIntensity: 0.1,
              gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
              inverseColors: true,
              opacityFrom: 0.7,
              opacityTo: 0.3,
              stops: [0, 100],
              colorStops: [],
            },
          },
          markers: {
            size: 5,
            style: 'hollow',
            strokeColors: '#fff',
            strokeWidth: 2,
            strokeOpacity: 0.8,
            strokeDashArray: 0,
            fillOpacity: 1,
            sizeOffset: 1,
          },
          stroke: {
            curve: 'straight',
            width: 2,
          },
          xaxis: {
            labels: {
              formatter: function (value) {
                const dateValue = moment(value + '01 +0000', 'YYYYMMDD Z')
                return dateValue.format('MMM YY')
              },
              offsetX: -5,
              style: {
                colors: '#4D4D4D',
                fontSize: '11px',
              },
            },
          },
          plotOptions: {
            bar: {
              columnWidth: '30%',
            },
          },
          tooltip: {
            x: {
              format: 'MMM yyyy',
            },
          },
          grid: {
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5,
            },
          },
        },
      }
    },
    computed: {
      ...mapGetters('app', {
        customerInfo: 'customerInfo',
        snCount: 'snCount', //, ...
      }),
      ...mapGetters('usage', {
        domesticVoiceCategory: 'domesticVoiceCategory',
        refreshRequired: 'refreshRequired',
        selectedTab: 'selectedTab',
      }),
      lineChartTitle () {
        if (this.domesticVoiceCategory.dataType === 'CALL') {
          return '12 months total National voice'
        }
        if (this.domesticVoiceCategory.dataType === 'DURATION') {
          return '12 months total National voice duration'
        }
        if (this.domesticVoiceCategory.dataType === 'SMS') {
          return '12 months total National SMS'
        }
        return '12 months total'
      },
    },
    watch: {
      domesticVoiceCategory: {
        handler () {
          if (this.cacheChartData.totalCall) {
            this.switch12MonthsTotal(this.domesticVoiceCategory)
          }
        },
      },
      dataYearMonth: {
        handler () {
          console.log('watching dataYearMonth changed to ' + this.dataYearMonth)
          this.updateAnnotationOnChart(this.dataYearMonth)
        },
      },
      selectedTab: {
        handler () {
          console.log('selected tab', this.selectedTab)
          if (this.selectedTab === 'NATIONAL VOICE') {
            this.switch12MonthsTotal(this.domesticVoiceCategory)
          }
        },
      },
    },
    mounted () {
      if (this.refreshData) {
        console.log('mounted 12 months line chart data category is ', this.dataCategory)
        findLast12MonthsDomesticVoiceAndSmsTotal(this.customerInfo.earliest, this.customerInfo.latest).then(result => {
          // findLast12MonthsDomesticVoiceTotal(this.customerInfo.earliest, this.customerInfo.latest).then(result => {
          console.log('find12MonthsDomesticVoiceTotalCall data total call completed length is ' + result.totalCall.length)
          this.cacheChartData = result // result contains {totalCall , totalDuration}
          this.minYearMonth = this.customerInfo.earliest
          this.maxYearMonth = this.customerInfo.latest
          this.switch12MonthsTotal(this.domesticVoiceCategory)
          this.refreshData = false
        })
      }
    },
    methods: {
      updateAnnotationOnChart (yearMonth) {
        console.log('updateAnnotationOnChart yearMonth is ' + yearMonth)
        const dateValue = moment(yearMonth + '01 +0000', 'YYYYMMDD Z')
        this.chartOptions = {
          ...this.chartOptions,
          ...{
            annotations: {
              xaxis: [
                {
                  // in a datetime series, the x value should be a timestamp, just like it is generated below
                  // x: moment(yearMonth + '01 +0000', 'YYYYMMDD Z').valueOf(),
                  x: dateValue.format('MMM YY'),
                  strokeDashArray: 0,
                  borderColor: '#775DD0',
                  label: {
                    borderColor: '#775DD0',
                    style: {
                      color: '#fff',
                      background: '#775DD0',
                    },
                    text: 'Top ten',
                  },
                },
              ],
            },
          },
        }
      },
      switch12MonthsTotal (dataCategory) {
        if (dataCategory.dataType === 'CALL') {
          let chartData = []
          this.cacheChartData.totalCall.forEach(dataCall => {
            // chartData.push([moment(dataCall.yearMonth + '01 +0000', 'YYYYMMDD Z').valueOf(), dataCall.total])
            chartData.push({ x: dataCall.yearMonth, y: Math.round(dataCall.total)})
          })
          this.updateLineChartData({ dataCategory: dataCategory.dataType, min: this.customerInfo.earliest, max: this.customerInfo.latest, data: chartData })
        }
        if (dataCategory.dataType === 'DURATION') {
          let chartData = []
          this.cacheChartData.totalDuration.forEach(dataCall => {
            // chartData.push([moment(dataCall.yearMonth + '01 +0000', 'YYYYMMDD Z').valueOf(), Math.round(dataCall.total)])
            chartData.push({ x: dataCall.yearMonth, y: Math.round(dataCall.total)})
          })
          this.updateLineChartData({ dataCategory: dataCategory.dataType, min: this.customerInfo.earliest, max: this.customerInfo.latest, data: chartData })
        }
        if (dataCategory.dataType === 'SMS') {
          let chartData = []
          this.cacheChartData.totalSms.forEach(dataCall => {
            // chartData.push([moment(dataCall.yearMonth + '01 +0000', 'YYYYMMDD Z').valueOf(), Math.round(dataCall.total)])
            chartData.push({ x: dataCall.yearMonth, y: Math.round(dataCall.total)})
          })
          this.updateLineChartData({ dataCategory: dataCategory.dataType, min: this.customerInfo.earliest, max: this.customerInfo.latest, data: chartData })
        }
      }, // end of switch total
      updateLineChartData (domesticVoiceLineChartData) {
        const min = moment(domesticVoiceLineChartData.min + '01 +0000', 'YYYYMMDD Z').subtract(5, 'd')
        const max = moment(domesticVoiceLineChartData.max + '01 +0000', 'YYYYMMDD Z').add(30, 'd')
        let yAxis = []
        if (domesticVoiceLineChartData.dataCategory === 'SMS') {
          yAxis = this.customYaxisSms
        }
        if (domesticVoiceLineChartData.dataCategory === 'DURATION') {
          yAxis = this.customYaxisDuration
        }
        if (domesticVoiceLineChartData.dataCategory === 'CALL') {
          yAxis = this.customYaxisCall
        }
        this.chartOptions = {
          ...this.chartOptions,
          ...{
            yaxis: yAxis,
          }
        }
        this.series = [{
          name: domesticVoiceLineChartData.dataCategory,
          data: domesticVoiceLineChartData.data,
        }]
      },
    },
  }
</script>

<style scoped>

</style>
