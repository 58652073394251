<template>
  <v-row class="pt-0">
    <v-col lg="12" class="pl-3 pr-3 pt-0">
      <v-card class="OnHover pa-3">
        <v-row>
          <v-col>
            <v-simple-table
              min-height="800px"
              max-height="1200px"
            >
              <tr>
                <td
                  style="vertical-align:top;padding-top:80px;width: 200px"
                >
                  <v-card
                    class="innerbody"
                    style="border-right:1px solid #EEEEEE"
                  >
                    <v-chip-group
                      v-model="selectedOptions"
                      active-class="deep-purple--text text--accent-2"
                      column
                      class="pl-1"
                    >
                      <v-chip
                        v-for="tag in tags"
                        :key="tag.name"
                        filter
                        outlined
                        :disabled="tag.disabled"
                      >
                        {{ tag.text }}
                      </v-chip>
                    </v-chip-group>
                  </v-card>
                </td>
                <td
                  style="vertical-align:top"
                  class="OnHover"
                >
                  <v-data-table
                    :headers="headers"
                    :items="tableData"
                    :items-per-page="50"
                    loading-text="Loading data...."
                    :loading="loading"
                    :options="dataTableOptions"
                    dense
                    class="centerheadings"
                  >
                    <template v-slot:item="{ item, select }">
                      <tr @click="handleRowClick(item)">
                        <td style="min-width:100px"><v-icon v-if="item.rowIndex == selectedRow"> mdi-forward </v-icon>{{ item.sn }}</td>
                        <td style="min-width:200px">
                          {{ item.userName }}
                        </td>
                        <td style="min-width:120px">
                          {{ Utils.formatDate(item.serviceStartDate) }}
                        </td>
                        <td>
                          {{ formatCurrency(item.billTotal) }}
                        </td>
                        <template v-for="header in headers">
                          <td class="text-center" v-if="item[header.value] === 'YES' || item[header.value] === 'Y'">
                            <v-icon color="grey lighten-1"> clear </v-icon>
                          </td>
                          <td class="text-center" v-if="item[header.value] === 'NO' || item[header.value] === 'N'">
                            <v-icon color="green darken-2"> mdi-check-bold </v-icon>
                          </td>
                        </template>
                        <td>
                          {{ Utils.formatDate(item.lastUsedNetworkDate) }}
                        </td>
                        <td>
                          {{ item.last3MonthsMostUsedSite }}
                        </td>
                        <td>
                          {{ item.last3MonthsMostUsedPostcode }}
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2 pa-0"
                    :before-generate="beforeGenerateExcel"
                    :before-finish="beforeFinishExcel"
                    @click="handleDownload"
                  >
                    Download <v-icon small class="mr-1" right> mdi-download </v-icon>
                  </v-btn>
                </td>
              </tr>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import Utils from '@/api/util'
import moment from 'moment'
import { mapGetters } from 'vuex'
import { findMonthlyDataTable, filterNoUsageDataTable } from '@/api/PouchDaoReport'
import store from '@/store'
import { filterUsers } from '@/api/ConfiguredOptionReport'

const monthYearPattern = /^((20)\d{4}$)/

export default {
  name: 'UsageDataTable',
  props: {
    dataCategory: {
      type: String,
      default: 'DOMESTIC DATA'
    },
    dataType: {
      type: String,
      default: 'CALL'
    },
    dataYearMonth: {
      type: Number
    }
  },
  computed: {
    Utils () {
      return Utils
    },
    ...mapGetters('app', {
      customerInfo: 'customerInfo'
    }),
    ...mapGetters('usage', {
      refreshRequired: 'refreshRequired',
      defaultTopTenMonth: 'defaultTopTenMonth'
    }),
    excelFileName() {
      if (this.dataCategory) {
        return this.dataCategory + ' Service Numbers'
      } else {
        return 'usageDataTable'
      }
    },
    excelTitle() {
      return this.dataCategory + ' ' + this.dataType
    }
  },
  data() {
    return {
      selectedRow: -1,
      excelHeaderRow: {},
      excelMeta: [
        [
          {
            key: 'charset',
            value: 'utf-8'
          }
        ]
      ],
      dataTableOptions: {
        itemsPerPage: 30
        // sortBy: ['201711'],
        // sortDesc: [true]
      },
      loading: false,
      tags: [
        {
          name: 'noNetworkActivityInLast3Months',
          count: 0,
          text: 'No Network Activity In Last 3 Months'
        },
        {
          name: 'noNetworkActivityInLast6Months',
          count: 0,
          text: 'No Network Activity In Last 6 Months'
        },
        {
          name: 'onlyDiversionUsageInLast3Months',
          count: 0,
          text: 'Only Diversion Usage In Last 3 Months'
        },
        {
          name: 'onlyDiversionUsageInLast6Months',
          count: 0,
          text: 'Only Diversion Usage In Last 6 Months'
        }
      ],
      selectedOptions: '',
      headers: [
        {
          text: 'Mobile No.',
          align: 'start',
          value: 'sn'
        },
        { text: 'User Name', value: 'userName' },
        { text: 'Service Start Date', value: 'serviceStartDate' },
      ],
      tableData: [],
      topTenLookUpMap: new Map(),
      excelFileType: 'xlsx'
    }
  },
  watch: {
    dataType: {
      handler() {
        this.updateHeaders()
      }
    },
    dataYearMonth: {
      handler() {
        console.log('dataYearMonth changed ', this.dataYearMonth)
        this.sortColumn(this.dataYearMonth)
      }
    },
    defaultTopTenMonth: {
      handler() {
        console.log('defaultTopTenMonth changed', this.defaultTopTenMonth)
        this.sortColumn(this.dataYearMonth)
      }
    },
    selectedOptions: 'filterNoUsageData',
  },
  mounted() {
    console.log('mounted dataCategory ' + this.dataCategory + ' dataType ' + this.dataType)
    this.init()
  },
  methods: {
    init () {
      this.updateHeaders()
      this.filterNoUsageData()
    },
    handleRowClick(value) {
      console.log('handleRowClick rowindex is ' + value.rowIndex)
      this.selectedRow = value.rowIndex
      store.dispatch('usage/updateSnFilter', { sn: value.sn })
    },
    sortColumn(columnName) {
      console.log('sortColumn called for ' + columnName)
      if (columnName) {
        this.dataTableOptions = {
          itemsPerPage: 30,
          sortBy: [columnName + ''],
          sortDesc: [true]
        }
      } else {
        this.dataTableOptions = {
          itemsPerPage: 30,
          sortBy: [],
          sortDesc: [true]
        }
      }
    },
    updateHeaders() {
      const header = [...this.headers]
      const yearMonthDesc = moment(this.dataYearMonth + '01', 'YYYYMMDD').format('MMM YYYY')

      header.push( { text: 'Bill Total ' + yearMonthDesc, value: 'billTotal' },
        { text: 'No Network Activity In Last 3 Months', value: 'noNetworkActivityInLast3Months' },
        { text: 'No Network Activity In Last 6 Months', value: 'noNetworkActivityInLast6Months' },
        { text: 'Only Diversion Usage In Last 3 Months', value: 'onlyDiversionUsageInLast3Months' },
        { text: 'Only Diversion Usage In Last 6 Months', value: 'onlyDiversionUsageInLast6Months' },
        { text: 'Last Used Network Date', value: 'lastUsedNetworkDate' },
        { text: 'Last 3 Months Most Used Site', value: 'last3MonthsMostUsedSite' },
        { text: 'Last 3 Months Most Used Postcode', value: 'last3MonthsMostUsedPostcode' })

      this.headers = header
    },
    filterNoUsageData() {
      let searchOptions = ''
      this.loading = true
      if ((this.selectedOptions !== '') && (this.selectedOptions !== undefined)){
        searchOptions = this.tags[this.selectedOptions].name
      }
      filterNoUsageDataTable(this.dataCategory, this.dataType, searchOptions).then((result) => {
        let rowIndex = 0
        let dataRows = []
        result.forEach((row) => {
          const data = row.doc
          data.rowIndex = rowIndex++
          data.billTotal = row.doc[this.dataYearMonth]
          dataRows.push(data)
        })

        if (this.selectedOptions === '') {
          this.buildHeroGraphData(result)
        }
        this.tableData = dataRows
        this.selectedRow = -1
        this.loading = false
      })
    },
    buildHeroGraphData (data) {
      const result = this.tags;
      data.forEach(item => {
        Object.keys(item.doc).forEach(key => {
          if (item.doc[key] === "N") {
            const property = result.find(prop => prop.name === key);
            if (property) {
              property.count++;
            }
          }
        });
      });

      result.map(item => {
        item.text = `${item.text} (${item.count})`
        if (item.count === 0){
          item.disabled = true
        }
      });
      this.tags = result
    },
    formatCurrency(data) {
      return Utils.formatCurrency(data)
    },
    beforeGenerateExcel() {
      store.dispatch('app/setProgress', true)
    },
    beforeFinishExcel() {
      store.dispatch('app/setProgress', false)
    },
    handleDownload() {
      import('@/vendor/Export2Excel').then((excel) => {
        const tHeader = []
        const filterVal = []
        const dateFields = ['serviceStartDate', 'lastUsedNetworkDate']

        this.headers.forEach((item, index) => {
          tHeader.push(item.text)
          filterVal.push(item.value)
        })

        const list = this.tableData
        const data = list.map((v) =>
                          filterVal.map((j) =>
                            dateFields.includes(j) ? Utils.formatDate(v[j]) : v[j]
                          )
                      )
        excel.export_json_to_excel({
          header: tHeader,
          sheetTitle: 'Usage',
          data,
          filename: this.excelFileName,
          autoWidth: this.autoWidth,
          bookType: this.excelFileType
        })
      })
    }
  }
}
</script>
<style></style>
